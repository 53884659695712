import React from 'react';
import { useParams, Link } from 'react-router-dom';

import { IconButtonWithTooltip } from '@components';
import { PermissionIcon } from '@svgAsComponents';

const PermissionIconWithLink = ({
  roleId,
  dataTestName,
}: {
  roleId: string;
  dataTestName: string;
}) => {
  const { companyId } = useParams();
  const pathBase = `/company/${companyId}/roles`;

  return (
    <Link to={`${pathBase}/${roleId}/permissions`}>
      <IconButtonWithTooltip data-cy={dataTestName} tooltipText="Permissions">
        <PermissionIcon />
      </IconButtonWithTooltip>
    </Link>
  );
};

export default PermissionIconWithLink;
