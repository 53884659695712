import React, { FC, ReactElement } from 'react';
import { Stack, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';
import { colors } from '@theme';
import { CloseIcon } from '@svgAsComponents';
import { IconButtonWithTooltip } from '@components';

type MaybeRenderProp = string | (() => ReactElement);

const renderOrCall = (value: MaybeRenderProp) =>
  typeof value === 'string' ? value : typeof value === 'function' ? value() : null;

const FullScreenDialog: FC<{
  open: boolean;
  title?: MaybeRenderProp;
  content: MaybeRenderProp;
  actionButtons?: MaybeRenderProp;
  actionIcons?: MaybeRenderProp;
  onClose?: () => void;
}> = ({ open, title, content, onClose = () => undefined, actionButtons, actionIcons }) => {
  return (
    <Dialog fullScreen open={open}>
      <Typography
        variant="h1"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: 78,
          padding: '16px 24px',
          displayPrint: 'none',
        }}
      >
        {renderOrCall(title)}
        <Stack direction="row">
          {renderOrCall(actionIcons)}
          <IconButtonWithTooltip sx={{ marginLeft: 'auto' }} onClick={onClose} tooltipText="Close">
            <CloseIcon size={18} />
          </IconButtonWithTooltip>
        </Stack>
      </Typography>
      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 2,
          border: 'none',
          gap: '16px',
          backgroundColor: colors.background.gray,
        }}
      >
        {renderOrCall(content)}
      </DialogContent>
      {actionButtons && (
        <DialogActions sx={{ displayPrint: 'none', padding: '24px' }}>
          {renderOrCall(actionButtons)}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default FullScreenDialog;
