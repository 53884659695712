import React, { FC, memo, useContext, useMemo } from 'react';
import { CommonRowType, MilestoneListColumnTypeV2 } from './common';
import { DocumentsIconWithIndicator, PhotoGalleryIconWithIndicator } from '@svgAsComponents';
import { RequestTableTabsEnum } from '@interfaces';
import { IconButtonWithTooltip, VerticalMenu } from '@components';
import { colors } from '@theme';
import { SettingsContext } from '@context';
import { DrawerContext } from '../drawer';

const DocumentsPhotosMenu: FC<{ row: CommonRowType }> = ({ row }) => {
  const { updateRightDrawer, source } = useContext(DrawerContext);
  const { isPHBProject } = useContext(SettingsContext);

  const menuItems = useMemo(
    () => [
      {
        action: updateRightDrawer
          ? updateRightDrawer({
              milestoneId: row?.id,
              milestoneSubmitId: row?.milestone_submit_id,
              milestoneName: row?.name,
              tab: RequestTableTabsEnum.DOCUMENTS,
            })
          : row?.updateRightDrawer?.(),
        text: 'Documents',
        icon: (
          <DocumentsIconWithIndicator
            hasDocuments={row.documents_preview?.has_docs}
            hasUnreadDocuments={row.documents_preview?.has_not_seen_docs}
          />
        ),
        dataTestName: `${source}__body__documents_gallery__menu_item`,
      },
      ...(isPHBProject
        ? []
        : [
            {
              action: updateRightDrawer?.({
                tab: RequestTableTabsEnum.PHOTOS,
                milestoneId: row?.id,
                milestoneSubmitId: row?.milestone_submit_id,
                milestoneName: row?.name,
              }),
              text: 'Progress photos',
              icon: (
                <PhotoGalleryIconWithIndicator
                  color={colors.icons.green}
                  hasImages={row.documents_preview?.has_photos}
                  hasUnseenImages={row.documents_preview?.has_not_seen_photos}
                />
              ),
              dataTestName: `${source}__body__photos_gallery__menu_item`,
            },
          ]),
    ],
    [row, updateRightDrawer, source, isPHBProject],
  );
  if (row.id === 'totals') return null;
  return (
    <VerticalMenu menuItems={menuItems}>
      <IconButtonWithTooltip
        data-cy={`${source}__body__documents_gallery__icon`}
        tooltipText="Upload documents / progress photos"
      >
        <DocumentsIconWithIndicator
          hasDocuments={row.documents_preview?.has_docs || row.documents_preview?.has_photos}
          hasUnreadDocuments={
            row.documents_preview?.has_not_seen_docs || row.documents_preview?.has_not_seen_photos
          }
        />
      </IconButtonWithTooltip>
    </VerticalMenu>
  );
};

const Memoized = memo(DocumentsPhotosMenu);

const documentsPhotosGalleryMenu: MilestoneListColumnTypeV2 = {
  field: 'documents_photos_gallery_menu',
  headerAlign: 'center',
  headerClassName: 'icon',
  cellClassName: 'icon',
  editable: false,
  renderHeader: () => null,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <Memoized row={params.row} />,
  maxWidth: 40,
  minWidth: 40,
};

export default documentsPhotosGalleryMenu;
