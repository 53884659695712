import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { Box, IconButton, Stack, Switch, Tooltip, Typography } from '@mui/material';
import truncate from 'lodash/truncate';
import {
  CompanyPrivacyCellProps,
  DateUploadedCellProps,
  DocumentActionsProps,
  NameCellProps,
  UploadedByCellProps,
} from '../interface';
import { colors, typography } from '@theme';
import {
  checkIsCustomerSuccess,
  documentCanBeProcessed,
  isCreatedByUser,
  isRestricted,
} from '@utils';
import {
  CommentIconWithIndicator,
  DownloadIcon,
  EditIcon,
  HistoryIcon,
  MagicWandIcon,
  NoTypeIcon,
} from '@svgAsComponents';
import { IconButtonWithTooltip } from '@components';
import { fileTypesMap, MAX_TEXT_LENGTH, TOOLTIP_TEXTS } from '@constants';
import { PermissionNamesEnums, VisibilityEnum } from '@interfaces';
import { useDateFormatter } from '@hooks';
import { PermissionsContext } from '@context';

export const DocumentActionsCell = ({
  params,
  source,
  isMilestoneDocs,
  openComments,
  restoreDocument,
  setActiveDocument,
  openDocumentSummary,
  isAdmin,
  isCurrentProjectArchived,
  canBeEdited,
}: DocumentActionsProps) => {
  const downloadsRef = useRef([]);

  const {
    deleted,
    name,
    id: documentId,
    link,
    created_by,
    comments_preview,
    has_smart_summary,
  } = params.row;

  return (
    <Stack
      direction="row"
      justifyContent={isMilestoneDocs ? 'flex-end' : 'flex-start'}
      alignItems="center"
    >
      {documentCanBeProcessed(params.row) && has_smart_summary ? (
        <Stack
          sx={{
            width: 32,
            height: 32,
            borderRadius: '2px',
            backgroundColor: colors.main.primary.lightest,
            justifyContent: 'center',
            alignItems: 'center',
            mr: 1,
          }}
        >
          <IconButtonWithTooltip
            tooltipText="Smart summary"
            onClick={() => openDocumentSummary(params.row)}
            data-cy={`${source}__document_summary__icon`}
          >
            <MagicWandIcon />
          </IconButtonWithTooltip>
        </Stack>
      ) : (
        <Stack sx={{ width: 32, height: 32, mr: 1 }} />
      )}
      {isAdmin && deleted && (
        <IconButtonWithTooltip
          tooltipText={
            isCurrentProjectArchived ? TOOLTIP_TEXTS.isCurrentProjectArchived : 'Restore'
          }
          onClick={() => {
            restoreDocument(documentId, name);
          }}
          disabled={isCurrentProjectArchived}
        >
          <HistoryIcon size={24} />
        </IconButtonWithTooltip>
      )}

      {!deleted && (
        <IconButtonWithTooltip
          tooltipText="Edit"
          onClick={() => setActiveDocument(params.row)}
          data-cy={`${source}__edit__icon`}
          disabled={!canBeEdited(created_by?.user_id)}
        >
          <EditIcon size={24} />
        </IconButtonWithTooltip>
      )}
      {link && (
        <Tooltip title="Download">
          <a
            href={link}
            download
            ref={(element) => (downloadsRef.current[params.row.index] = element)}
          >
            <IconButton data-cy={`${source}__download__icon`} data-documentname={name}>
              <DownloadIcon size={24} />
            </IconButton>
          </a>
        </Tooltip>
      )}
      <IconButtonWithTooltip
        tooltipText="Comments"
        onClick={() => openComments(params.row)}
        data-cy={`${source}__comments__icon`}
      >
        <CommentIconWithIndicator
          hasComments={comments_preview?.has_comments}
          hasUnreadComments={comments_preview?.has_unread_comments}
        />
      </IconButtonWithTooltip>
    </Stack>
  );
};

export const CompanyPrivacyCell = ({
  params,
  source,
  userId,
  updateDocumentPrivacy,
}: CompanyPrivacyCellProps) => {
  const { created_by, id, scope } = params.row;

  const [checked, setChecked] = React.useState(scope !== VisibilityEnum.EVERYONE);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    updateDocumentPrivacy({
      documentId: id,
      scope: event.target.checked ? VisibilityEnum.COMPANY : VisibilityEnum.EVERYONE,
    });
  };

  return (
    <Tooltip title={checked ? 'Make public' : 'Make private'}>
      <Switch
        checked={checked}
        onChange={handleChange}
        disabled={!isCreatedByUser(created_by?.user_id, userId)}
        sx={{ ml: 2 }}
        data-cy={`${source}__company_private__switch`}
      />
    </Tooltip>
  );
};

export const NameCell = ({ params, source, open }: NameCellProps) => {
  const { name, file, mime_type, file_representations, document_type } = params.row;

  const Icon =
    fileTypesMap[mime_type] ||
    fileTypesMap[file_representations?.original?.mime_type] ||
    NoTypeIcon;

  const canBeOpen = documentCanBeProcessed(params.row);
  return (
    <Tooltip title={name?.length > MAX_TEXT_LENGTH ? name : ''}>
      <Typography
        variant="body3"
        style={{
          display: 'flex',
          alignItems: 'center',
          ...(canBeOpen && {
            ...typography.body3SemiBold,
            color: colors.text.link,
            cursor: 'pointer',
          }),
          ...(!file && typography.body3SemiBold),
        }}
        onClick={() => (canBeOpen ? open(params.row) : {})}
        data-cy={`${source}__name__value`}
      >
        <Icon />
        <Box sx={{ pr: 1 }} />
        {truncate(name || document_type, { length: MAX_TEXT_LENGTH })}
      </Typography>
    </Tooltip>
  );
};

export const DateUploadedCell = ({ params, source }: DateUploadedCellProps) => {
  const { dateFormatter } = useDateFormatter();

  const { created_at } = params.row;

  return (
    <Typography variant="body3" data-cy={`${source}__date_uploaded__value`}>
      {dateFormatter({ date: created_at })}
    </Typography>
  );
};

export const UploadedByCell = ({ params, source }: UploadedByCellProps) => {
  const { permissions } = useContext(PermissionsContext);
  const { created_by_service_name, created_by } = params.row;

  const isUploadedByCustomerSuccess = useMemo(
    () => checkIsCustomerSuccess(created_by?.primary_role),
    [created_by?.primary_role],
  );

  const hasCSPermission = useMemo(
    () => !isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions),
    [permissions],
  );

  const getUploadedBy = useCallback(() => {
    if (isUploadedByCustomerSuccess) {
      return hasCSPermission ? created_by?.full_name : 'Trustpoint';
    }
    return created_by_service_name || created_by?.full_name;
  }, [isUploadedByCustomerSuccess, hasCSPermission, created_by, created_by_service_name]);

  return (
    <Typography variant="body3" data-cy={`${source}__uploaded_by__value`}>
      {getUploadedBy()}
    </Typography>
  );
};
