import { useLocation, useNavigate } from 'react-router-dom';

interface CustomizedState {
  fromRedirect: boolean;
  isAutoLogout: boolean;
  from: string;
  ssoAccessToken: string;
  fromResetPassword: boolean;
}

export const useNavigateToOriginalUrl = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state as CustomizedState;

  // location.state - 'fromRedirect' flag is used to identify redirect after okta sso login
  // to define if it's initial sso login page or after redirect callback

  const { fromRedirect, isAutoLogout, from, ssoAccessToken, fromResetPassword } = state || {};

  // local storage is used to keep the original url after the user will get link to set password and then will go to our origin from email
  const originalUrl = JSON.parse(
    sessionStorage.getItem('originalUrl') || localStorage.getItem('originalUrl') || 'null',
  );

  const deepLink = originalUrl || from || '/';

  const handleRedirect = () => {
    if (originalUrl) sessionStorage.removeItem('originalUrl');
    navigate(deepLink);
  };

  return {
    handleRedirect,
    fromRedirect,
    isAutoLogout,
    from,
    ssoAccessToken,
    originalUrl,
    fromResetPassword,
  };
};
