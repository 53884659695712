import React, { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

import { DrawRequestTypeEnum, HookState, MessagePanelTabsEnum, PopupTypeEnum } from '@interfaces';
import {
  BarChart,
  ButtonWithPopper,
  ButtonWithTooltip,
  ConfirmationModal,
  LoadingSkeleton,
  MessagePanel,
  MessagePanelButton,
  ProjectNameLabel,
  RightDrawer,
  ServiceMessage,
  StyledBox,
  SuccessModal,
  IconButtonWithTooltip,
} from '@components';
import { colors } from '@theme';
import { ArrowBackIcon } from '@svgAsComponents';
import { Checklist, SubmissionPopoverContent } from '../components/';
import {
  useButtonHandler,
  useCancelRequest,
  useChecklist,
  useFields,
  useMilestoneList,
  useRequest,
  useSubmission,
  useValidation,
} from './controllers/';
import { ConfirmationChangeRequest, ConfirmationDraw } from '../components/confirmation';
import {
  ConfirmationModalTypeEnums,
  IButtonController,
  ICancelRequestController,
  IChecklistController,
  IFieldsController,
  IRequestController,
  ISubmissionController,
  IValidationController,
} from './interfaces';
import MilestoneListModal from '../components/MilestoneListModal';
import { ModalBottomButtons } from '../../../components/DrawRequestOperationButtons/components/ApprovalModals';

const DrawRequestInit = () => {
  const requestController = useRequest();

  switch (requestController.state) {
    case HookState.FETCHING: {
      return (
        <StyledBox>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text={requestController.error.toString()} />;
    }

    case HookState.SUCCESS: {
      return <DetailsBody requestController={requestController} />;
    }

    default:
      return null;
  }
};

export default DrawRequestInit;

const DetailsBody: FC<{ requestController: IRequestController }> = ({ requestController }) => {
  const { mainRef, project, drawRequest } = requestController;
  const cancelRequestController = useCancelRequest();
  const checklistController = useChecklist();
  const fieldsController = useFields({
    checklistItems: checklistController.checklistItems,
    isRejectionResubmit: requestController.isRejectionResubmit,
  });
  const {
    completionDate,
    comment,
    inspectionComment,
    completionDateComment,
    completionDateReasons,
    isCompletionDateConfirmed,
    isInspectionValid,
    inspectionFields,
    completionDateValid,
  } = fieldsController;
  const validationController = useValidation({
    completionDateValid,
    isInspectionValid,
    handleDone: checklistController.handleDone,
  });
  const submissionController = useSubmission({
    completionDate,
    completionDateComment,
    completionDateReasons,
    comment,
    inspectionComment,
    inspectionFields,
  });
  const milestoneListController = useMilestoneList({
    drawRequest,
    isReallocationAllowed: requestController.isReallocationAllowed,
  });
  const buttonsController = useButtonHandler({
    completionDate,
    completionDateComment,
    completionDateReasons,
    isCompletionDateConfirmed,
    inspectionComment,
    inspectionFields,
    validatedSection: validationController.validatedSection,
    checklistItems: checklistController.checklistItems,
    handleDone: checklistController.handleDone,
    goBack: cancelRequestController.goBack,
    isAnyItemChanged: validationController.isAnyItemChanged,
    setCancelModalShow: cancelRequestController.setCancelModalShow,
  });

  if (validationController.isEditTable)
    return (
      <MilestoneListModal
        controller={milestoneListController}
        checklistController={checklistController}
        validationController={validationController}
        requestController={requestController}
        isReallocationAllowed={requestController.isReallocationAllowed}
      />
    );

  return (
    <Stack direction="column" sx={{ height: '100%', scrollMargin: '64px' }} ref={mainRef}>
      <Stack sx={{ p: 3 }} direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row">
          <IconButtonWithTooltip tooltipText="Go back" onClick={buttonsController.onSaveAsDraft}>
            <ArrowBackIcon />
          </IconButtonWithTooltip>
          <Typography sx={{ ml: 2 }} variant="h1">
            {requestController.isRejectionResubmit
              ? `Edit request #${drawRequest.counter_per_request_type}`
              : `Request #${drawRequest.counter_per_request_type} submission`}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={3}>
          <ProjectNameLabel project={requestController.project} />
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography variant="body1SemiBold">
              {`${validationController.barChartInfo.completedSteps} / ${validationController.barChartInfo.allSteps}`}
            </Typography>
            <Box sx={{ width: '300px' }}>
              <BarChart
                stacked
                orientation="horizontal"
                values={[
                  validationController.barChartInfo.completedSteps,
                  validationController.barChartInfo.incompletedSteps,
                ]}
                total={validationController.barChartInfo.allSteps}
                barWidth={16}
                borderRadius="4px"
                colors={[colors.status.success.medium, colors.neutral.lighter]}
              />
            </Box>
          </Stack>
          <MessagePanelButton
            commentsPreview={requestController?.project?.comments_preview}
            handleRightDrawerOpenerClick={requestController?.handleRightDrawerOpenerClick}
            source="request_submission__main"
          />
        </Stack>
      </Stack>
      <Box
        sx={{
          backgroundColor: colors.background.gray,
          flex: 1,
          p: 3,
        }}
      >
        {buttonsController.isConfirmationStep ? (
          <Confirmations controller={{ ...requestController, ...fieldsController }} />
        ) : (
          <Checklist
            {...{
              buttonsController,
              checklistController,
              fieldsController,
              requestController,
              milestoneListController,
              validationController,
            }}
          />
        )}
      </Box>
      <Footer
        controller={{
          ...cancelRequestController,
          ...buttonsController,
          ...validationController,
          ...submissionController,
          ...checklistController,
          ...requestController,
        }}
      />
      <Modals
        controller={{ ...cancelRequestController, ...submissionController, ...fieldsController }}
      />

      <RightDrawer {...requestController?.rightMenu}>
        <MessagePanel
          projectId={project?.id}
          requestId={requestController?.drawRequest?.id}
          source={'request_submission__comments'}
          tab={MessagePanelTabsEnum.BORROWER}
        />
      </RightDrawer>
    </Stack>
  );
};

const Footer: FC<{
  controller: IButtonController &
    ICancelRequestController &
    ISubmissionController &
    IValidationController &
    IChecklistController &
    IRequestController;
}> = ({ controller }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopperClose = () => setAnchorEl(null);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      sx={{ position: 'relative', p: 3 }}
    >
      <Button
        variant="text"
        sx={{ mr: 1 }}
        onClick={controller.onStepClick}
        disabled={!controller.showSubmit}
        data-cy="request__submission__footer__back__button"
      >
        Back
      </Button>
      {controller.drawRequest?.can_be_deleted && (
        <Button
          variant="new"
          color="error"
          onClick={() => controller.setCancelModalShow(ConfirmationModalTypeEnums.CANCEL)}
          sx={{ mr: 1 }}
          data-cy="request__submission__footer__delete__button"
        >
          Delete draft
        </Button>
      )}
      <Button
        variant="new"
        color="secondary"
        onClick={controller.onSaveAsDraft}
        disabled={controller.isAdditionalRequirementResubmit}
        sx={{ mr: 1 }}
        data-cy="request__submission__footer__save__button"
      >
        {controller.isRejectionResubmit ? 'Save' : 'Save as draft'}
      </Button>
      {controller.isAdditionalRequirementResubmit ? (
        <ButtonWithTooltip
          onClick={controller.onResubmit}
          disabled={!controller.isAllDone || controller.isSubmitting}
          tooltipText={
            !controller.isAllDone
              ? 'You have some unresolved items above that must be addressed before continuing.'
              : ''
          }
          dataTestName="request__submission__footer__resubmit__button"
        >
          {controller.isSubmitting ? 'Submitting...' : 'Resubmit'}
        </ButtonWithTooltip>
      ) : controller.showSubmit ? (
        <ButtonWithTooltip
          onClick={controller.drawRequestSubmit}
          disabled={controller.isSubmitting}
          dataTestName="request__submission__footer__submit__button"
        >
          {controller.isSubmitting
            ? 'Submitting...'
            : controller.isRejectionResubmit
              ? 'Resubmit'
              : 'Submit'}
        </ButtonWithTooltip>
      ) : !controller.isAllDone ? (
        <ButtonWithPopper
          buttonText="Next"
          maxWidth="560px"
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          dataTestName="request__submission__footer__next__button"
          buttonClickCallback={controller.handleNextClick}
        >
          <SubmissionPopoverContent
            data={controller.undoneSections}
            handlePopperClose={handlePopperClose}
            handleNavigate={controller.handleNavigate}
          />
        </ButtonWithPopper>
      ) : (
        <ButtonWithTooltip
          color="primary"
          onClick={controller.onStepClick}
          disabled={!controller.isAllDone}
          tooltipText="You have some unresolved items above that must be addressed before continuing."
          dataTestName="request__submission__footer__next__button"
        >
          Next
        </ButtonWithTooltip>
      )}
    </Stack>
  );
};

const Modals: FC<{
  controller: ICancelRequestController & ISubmissionController & IFieldsController;
}> = ({ controller }) => {
  const isCancelRequest = controller.isCancelModalShow === ConfirmationModalTypeEnums.CANCEL;
  return (
    <>
      <ConfirmationModal
        open={Boolean(controller.isCancelModalShow)}
        title={isCancelRequest ? 'Delete request' : 'Warning'}
        text={
          isCancelRequest
            ? 'This action cannot be undone. All edits associated with this draw request will be permanently deleted.'
            : "The empty draft request will be deleted. Don't worry, you won't lose anything since no information was entered yet."
        }
        onClose={() => controller.setCancelModalShow(null)}
        confirmCallback={controller.requestCancel}
        type={PopupTypeEnum.ERROR}
        isLoading={controller.isCanceling}
        confirmButtonLabel={isCancelRequest ? 'Delete' : 'Confirm'}
        source={`request_submission__${controller.isCancelModalShow}`}
      />
      {controller.showSuccessPopup && (
        <SuccessModal
          dataTestName="submit-request-success-modal"
          text={controller.showSuccessPopup}
          open
          buttons={<ModalBottomButtons onClose={controller.onCloseSuccessModal} />}
        />
      )}
    </>
  );
};

const Confirmations: FC<{
  controller: IRequestController & IFieldsController;
}> = ({ controller }) => {
  return (
    <>
      {controller.drawRequest.type === DrawRequestTypeEnum.DRAW_REQUEST ? (
        <ConfirmationDraw
          projectRetainageRate={controller.project.retainage_rate}
          inspectionComment={controller.inspectionComment}
          inspectionRequestedAt={controller.inspectionFields.inspectionRequestedAt}
          drawRequest={controller.drawRequest}
          documentsSummary={controller.documentsSummary}
          feesAmount={controller.feesAmount}
          showDocumentsSection={controller.showDocumentsSection}
          totalRetainageRequested={controller.totalRetainageRequested}
          isReallocationAllowed={controller.isReallocationAllowed}
          milestones={controller.allMilestones}
        />
      ) : (
        <ConfirmationChangeRequest
          drawRequest={controller.drawRequest}
          documentsSummary={controller.documentsSummary}
          milestones={controller.allMilestones}
        />
      )}
    </>
  );
};
