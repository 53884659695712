import React, { FC, memo, useContext, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { CommonRowType, MilestoneListColumnTypeV2 } from './common';
import { AddDocumentIcon, AddPhotoIcon, AddProofpointIcon } from '@svgAsComponents';
import {
  DocumentContentTypeEnum,
  IDocumentType,
  QueryNamesEnums,
  TransloaditTemplateEnum,
} from '@interfaces';
import {
  DocumentUploaderWithForm,
  IconButtonWithTooltip,
  UploaderWithForm,
  VerticalMenu,
} from '@components';
import { Box } from '@mui/material';
import { colors } from '@theme';
import { useCommentsAndDocumentsPreview, useFilesUploader } from '@hooks';
import { getDrawRequestDocumentsTypeList, getProjectDocumentTypes } from '@globalService';
import { SettingsContext } from '@context';
import { DrawerContext } from '../drawer';

const DocumentsPhotosUploaderMenu: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source } = useContext(DrawerContext);
  const { projectId, requestId } = useParams();
  const { isPHBProject } = useContext(SettingsContext);

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    drawRequestId: requestId,
    milestoneId: row.id,
  });

  const {
    isFilesUploaderOpened,
    transloaditSignature,
    uploadMedia,
    filesUploaderClose,
    restrictions,
    refetchCallback,
  } = useFilesUploader();
  const [modalType, setModalType] = React.useState<TransloaditTemplateEnum>(null);

  const projectDocumentTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_TYPES],
    getProjectDocumentTypes.bind(this),
  );
  const drawRequestDocumentsTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS_TYPES],
    getDrawRequestDocumentsTypeList.bind(this),
  );
  const getContentType = () => {
    if (isPHBProject) return DocumentContentTypeEnum.MILESTONE_GROUP;
    if (row.milestone_submit_id) return DocumentContentTypeEnum.MILESTONE_SUBMIT;
    return DocumentContentTypeEnum.MILESTONE;
  };

  const menuItems = useMemo(
    () => [
      {
        action: () => {
          setModalType(TransloaditTemplateEnum.DOCUMENTS);
          uploadMedia({
            fields: {
              content_type: getContentType(),
              object_id: row.milestone_submit_id || row.id,
            },
            templateType: TransloaditTemplateEnum.DOCUMENTS,
          });
        },
        text: 'Upload document(s)',
        icon: <AddDocumentIcon />,
        disabled: !row.canAddPhotos,
        disabledTooltipText: 'You cannot upload documents here',
        dataTestName: `${source}__body__documents_uploader__menu_item`,
      },
      ...(isPHBProject
        ? []
        : [
            {
              action: () => {
                setModalType(TransloaditTemplateEnum.PROOFPOINTS);
                uploadMedia({
                  fields: {
                    ...(requestId && { draw_request_id: requestId }),
                    project_id: projectId,
                    milestone_id: row.id,
                    object_id: row.id || requestId || projectId,
                    content_type: DocumentContentTypeEnum.MILESTONE,
                  },
                  templateType: TransloaditTemplateEnum.PROOFPOINTS,
                });
              },
              text: 'Upload progress photo(s)',
              icon: <AddPhotoIcon color={colors.icons.green} />,
              disabled: !row.canAddPhotos,
              disabledTooltipText: 'You cannot upload progress photos here',
              dataTestName: `${source}__body__photos_uploader__menu_item`,
            },
          ]),
    ],
    [row, projectId, requestId, source, isPHBProject],
  );

  const refetchComments = () => {
    if (!isPHBProject) updateCommentsPreviewInfo();
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  if (row.id === 'totals') return null;

  return (
    <Box onMouseDown={stopPropagation} onClick={stopPropagation} onKeyDown={stopPropagation}>
      <VerticalMenu menuItems={menuItems}>
        <IconButtonWithTooltip
          data-cy={`${source}__body__photos_uploader__icon`}
          tooltipText="Upload documents / progress photos"
        >
          <AddProofpointIcon />
        </IconButtonWithTooltip>
      </VerticalMenu>
      {transloaditSignature?.signature && modalType === TransloaditTemplateEnum.DOCUMENTS && (
        <DocumentUploaderWithForm
          isOpen={isFilesUploaderOpened}
          documentTypes={
            requestId ? drawRequestDocumentsTypesQuery.data : projectDocumentTypesQuery.data
          }
          closeUploader={filesUploaderClose}
          transloaditSignature={transloaditSignature}
          refetchCallback={refetchCallback}
          refetch={[() => refetchComments()]}
          restrictions={restrictions}
          source={source}
          drawRequestId={requestId}
        />
      )}
      {isFilesUploaderOpened &&
        transloaditSignature &&
        modalType === TransloaditTemplateEnum.PROOFPOINTS &&
        ReactDOM.createPortal(
          <UploaderWithForm
            isOpen={isFilesUploaderOpened}
            closeUploader={filesUploaderClose}
            transloaditSignature={transloaditSignature}
            restrictions={restrictions}
            refetchCallback={refetchCallback}
            refetch={[() => refetchComments()]}
            source={source}
            projectId={projectId}
            drawRequestId={requestId}
            showLineItems
            milestoneId={row?.id}
          />,
          document.body,
        )}
    </Box>
  );
};

const Memoized = memo(DocumentsPhotosUploaderMenu);

const documentsPhotosUploaderMenu: MilestoneListColumnTypeV2 = {
  field: 'documents_photos_uploader_menu',
  headerAlign: 'center',
  headerClassName: 'icon',
  cellClassName: 'icon',
  renderHeader: () => null,
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <Memoized row={params.row} />,
  maxWidth: 40,
  minWidth: 40,
};

export default documentsPhotosUploaderMenu;
