export const project = (projectId = 'project_pk', query?: string) =>
  `projects/${projectId}${query ? `/?query=${query}` : '/'}`;
export const projectFunds = (projectId = 'project_pk') => `projects/${projectId}/funds/`;

export const projectMilestones = (projectId = 'project_pk') => `projects/${projectId}/milestones/`;

export const projectBuildingModels = (projectId = 'project_pk') =>
  `projects/${projectId}/building_models/`;

export const projectMilestoneTags = (projectId = 'project_pk') =>
  `projects/${projectId}/milestone_tag_categories/`;

export const projectMilestoneTagById = (
  projectId = 'project_pk',
  milestoneTagId = 'milestone_tag_pk',
) => `projects/${projectId}/milestone_tag_categories/${milestoneTagId}/`;

export const projectBuildingModelsDelete = (projectId = 'project_pk') =>
  `projects/${projectId}/building_models/delete/`;

export const projectBuildingModelById = (projectId = 'project_pk', modelId = 'modelId') =>
  `projects/${projectId}/building_models/${modelId}/`;

export const projectBuildingById = (projectId = 'project_pk', buildingId = 'buildingId') =>
  `projects/${projectId}/buildings/${buildingId}/`;

export const projectMilestonesDelete = (projectId = 'project_pk', queryFiltersParams = '') =>
  `projects/${projectId}/milestones/delete/?${queryFiltersParams}`;

export const projectMilestoneColumns = (projectId = 'project_pk') =>
  `projects/${projectId}/milestones/columns/`;

export const projectProgress = (projectId = 'project_pk') => `projects/${projectId}/progress/`;

export const projectBuildings = (projectId = 'project_pk') =>
  `projects/${projectId}/buildings/?limit=9999`;

export const projectBuildingsBulk = (projectId = 'project_pk') =>
  `projects/${projectId}/buildings/bulk/`;

export const projectScoresNew = (projectId = 'project_pk') => `projects/${projectId}/scores_new/`;
export const projectSettings = (projectId = 'project_pk') => `projects/${projectId}/ui_settings/`;
export const projectDocumentTypes = () => `documents/project/document_types/`;
export const projectDocumentTypeByName = (documentType = 'document_type_name') =>
  `documents/project/document_types/${documentType}/`;

export const projectMilestonesList = ({
  projectId = 'project_pk',
  queryFiltersParams = '',
  filterParams = '',
  milestoneTagsParams = '',
  typeKeysParams = '',
}) =>
  `projects/${projectId}/milestones/?${filterParams}${typeKeysParams}${milestoneTagsParams}${queryFiltersParams}`;

export const projectMilestonesGroupList = ({
  projectId = 'project_pk',
  queryFiltersParams = '',
  filterParams = '',
  milestoneTagsParams = '',
  typeKeysParams = '',
}) =>
  `projects/${projectId}/milestone_groups/?${filterParams}${typeKeysParams}${milestoneTagsParams}${queryFiltersParams}`;

export const projectTypes = (query?: string) => `projects/types/?limit=9999&${query}`;

export const projectPropertyTypes = (query?: string) => `buildings/types/?limit=9999${query}`;

export const projectPropertyDetailsNames = () => `buildings/detail_names/`;
export const projectExitStrategies = () => `projects/exit_strategies/`;
export const projectTeams = (projectId = 'project_pk', companyId = '') =>
  `projects/${projectId}/teams/?limit=100${companyId ? `&company=${companyId}` : ''}`;

export const projectTeamsWithQuery = (projectId = 'project_pk', query = '') =>
  `projects/${projectId}/teams/?limit=100${query && `&${query}`}`;

export const milestoneById = (project: string, milestone: string, query = '') =>
  `projects/${project}/milestones/${milestone}/${query && `?query=${query}`}`;

export const projectsTeamsWithQuery = ({ restQlParams }: { restQlParams: string }) =>
  `projects/teams/?limit=9999&is_customer=true&sorting=name&exclude_approval_level=0${
    restQlParams ? `&query=${restQlParams}` : ''
  }`;

export const projectPermissions = (projectId = 'project_pk') =>
  `projects/${projectId}/permissions/`;

export const projectPhotos = (projectId = 'project_pk', query = '') =>
  `projects/${projectId}/photos/${query && `?${query}`}`;

export const projectCoordinatorsWithQuery = ({ restQlParams }: { restQlParams: string }) =>
  `projects/users/?is_draw_request_coordinator=true&sorting=first_name${
    restQlParams ? `&query=${restQlParams}` : ''
  }`;

export const projectsUsers = (stringQueryParams) => `projects/users/${stringQueryParams || ''}`;

export const projectGroupStats = (stringQueryParams) =>
  stringQueryParams ? `projects/stats/?${stringQueryParams}` : 'projects/stats/';

export const workflowReasons = () => 'projects/workflow_reasons/';

export const projectThumb = (projectId = 'project_pk') => `projects/${projectId}/thumb/`;

export const projectMilestoneDocuments = (
  project: string,
  milestone: string,
  query: string,
  stringQueryParams: string,
) => `projects/${project}/milestones/${milestone}/documents/?${stringQueryParams}${query}`;

export const projectMilestoneGroupDocuments = (
  project: string,
  milestone: string,
  stringQueryParams: string,
) => `projects/${project}/milestone_groups/${milestone}/documents/?${stringQueryParams}`;

export const inviteProjectBorrowersPath = (projectId = 'project_pk') =>
  `projects/${projectId}/invite/`;

export const projectMSGroup = ({ project, group_by, milestone = '' }) =>
  `projects/${project}/milestone_groups/${milestone}/?group_by=${group_by}`;

export const projectWatchersPath = (projectId = 'project_pk', stringQueryParams = '') =>
  `projects/${projectId}/watchers/${stringQueryParams && `?${stringQueryParams}`}`;

export const projectWatchPath = (projectId = 'project_pk') => `projects/${projectId}/watch/`;

export const projectsWatchPath = () => `projects/watch/`;

export const projectUnWatchPath = (projectId = 'project_pk') => `projects/${projectId}/unwatch/`;

export const projectsUnWatchPath = () => `projects/unwatch/`;

export const projectListWithQuery = (stringQueryParams = '') => `projects/?${stringQueryParams}`;
