import { useParams } from 'react-router-dom';
import React from 'react';
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { HookState } from '@interfaces';
import {
  DisbursementStatement,
  FullScreenDialog,
  LabelAndValue,
  LoadingSkeleton,
  ProgressWithArrow,
  RequestPaymentConfigurationEditPopup,
  ServiceMessage,
  StyledBox,
  IconButtonWithTooltip,
} from '@components';
import { useRequestPaymentSummary } from './controller';
import { currencyFormatter, getStatusChip } from '@utils';
import { EditIcon } from '@svgAsComponents';
import FeesEditPopup from '../FeesEditPopup';
import { colors } from '@theme';

const PaymentSummary = () => {
  const { drawRequestId } = useParams();
  const {
    state,
    borrowerEquity,
    constructionHoldback,
    feesAmount,
    requestProportionText,
    drawRequest,
    progressValues,
    retainageRate,
    isInReview,
    transactionDate,
    transactionId,
    openEditFeesModal,
    setOpenEditFeesModal,
    canEditFees,
    openEditProportionModal,
    setOpenEditProportionModal,
    canEditProportion,
    showDisbursement,
    setShowDisbursement,
    isRequestApprovedOrCompleted,
  } = useRequestPaymentSummary();

  switch (state) {
    case HookState.FETCHING: {
      return (
        <>
          <StyledBox>
            <Skeleton />
          </StyledBox>
          <Box mb={2} />
          <LoadingSkeleton type="twoBlocks" />
        </>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="request payment summary" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack direction={{ lg: 'row', xs: 'column' }} spacing={1}>
          <StyledBox sx={{ flexGrow: 1 }}>
            <Stack flex={1} spacing={1}>
              <LabelAndValue
                label="Draw proportion"
                text={requestProportionText}
                icon={
                  canEditProportion ? (
                    <IconButtonWithTooltip
                      tooltipText="Edit"
                      sx={{ py: 0 }}
                      data-cy="payments_tab__draw_proportion__edit__icon"
                      onClick={() => setOpenEditProportionModal(true)}
                    >
                      <EditIcon size={24} />
                    </IconButtonWithTooltip>
                  ) : null
                }
                iconPosition="right"
              />
              <LabelAndValue label="Draw status">{getStatusChip(drawRequest)}</LabelAndValue>
              <LabelAndValue
                label="All fees"
                text={currencyFormatter(feesAmount, '-')}
                icon={
                  canEditFees ? (
                    <IconButtonWithTooltip
                      tooltipText="Edit"
                      onClick={() => setOpenEditFeesModal(true)}
                      sx={{ py: 0 }}
                      data-cy="payments_tab__fees__edit__icon"
                    >
                      <EditIcon size={24} />
                    </IconButtonWithTooltip>
                  ) : null
                }
                textDataTestName="payments_tab__all_fees__value"
                iconPosition="right"
              />
              {isRequestApprovedOrCompleted && (
                <LabelAndValue label="Disbursement statement">
                  <Typography
                    variant="body3SemiBold"
                    sx={{ color: colors.text.link, cursor: 'pointer' }}
                    data-cy="payments_tab__disbursement_statement__link"
                    onClick={() => setShowDisbursement(true)}
                  >
                    Show
                  </Typography>
                </LabelAndValue>
              )}
            </Stack>
          </StyledBox>
          <StyledBox sx={{ flexGrow: 1 }}>
            <Stack flex={1} spacing={1}>
              <LabelAndValue
                label="Construction holdback"
                text={currencyFormatter(constructionHoldback, '-')}
                textDataTestName="payments_tab__construction_holdback__value"
              />
              <LabelAndValue
                label="Total borrower equity for this draw"
                text={currencyFormatter(borrowerEquity, '-')}
                textDataTestName="payments_tab__borrower_equity__value"
              />
              {isRequestApprovedOrCompleted && (
                <>
                  <LabelAndValue
                    label="Transaction ID"
                    text={transactionId || '-'}
                    textDataTestName="payments_tab__transaction_id__value"
                  />
                  <LabelAndValue
                    label="Transaction date"
                    text={transactionDate || '-'}
                    textDataTestName="payments_tab__transaction_date__value"
                  />
                </>
              )}
            </Stack>
          </StyledBox>
          <StyledBox sx={{ flexGrow: 1 }}>
            <Stack flex={1} spacing={1}>
              <Typography
                variant="labelSemiBold"
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                Requested ➝ Approved
              </Typography>
              <LabelAndValue label="Total draw amount">
                <ProgressWithArrow
                  showProgress
                  prevValue={progressValues.requestedAmount}
                  nextValue={progressValues.approvedAmount}
                  getTextStyleForActive={isInReview}
                  prevValueDataTestName="payments_tab__requested_amount__value"
                  nextValueDataTestName="payments_tab__approved_amount__value"
                />
              </LabelAndValue>
              {Boolean(retainageRate) && (
                <LabelAndValue label={`Retainage balance to date (${retainageRate}%)`}>
                  <ProgressWithArrow
                    showProgress
                    prevValue={progressValues.prevRetainageBalance}
                    nextValue={progressValues.retainageBalance}
                    getTextStyleForActive={isInReview}
                    prevValueDataTestName="payments_tab__requested_retainage__value"
                    nextValueDataTestName="payments_tab__approved_retainage__value"
                  />
                </LabelAndValue>
              )}
            </Stack>
          </StyledBox>
          {openEditFeesModal && <FeesEditPopup onClose={() => setOpenEditFeesModal(false)} />}
          {openEditProportionModal && (
            <RequestPaymentConfigurationEditPopup
              drawRequestId={drawRequestId}
              onClose={() => setOpenEditProportionModal(false)}
            />
          )}
          <FullScreenDialog
            open={showDisbursement}
            title="Disbursement statement"
            actionButtons={() => (
              <Button
                onClick={window.print}
                variant="new"
                color="secondary"
                data-cy="payments_tab__disbursement_statement__print__button"
              >
                Print
              </Button>
            )}
            content={() => <DisbursementStatement drawRequestId={drawRequestId} />}
            onClose={() => setShowDisbursement(false)}
          />
        </Stack>
      );
    }
    default:
      return null;
  }
};

export default PaymentSummary;
