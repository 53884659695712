import { dataProvider } from '@api';
import {
  projectsCompaniesApi,
  projectsInspectionAgenciesApi,
  projectsTeamsApi,
  projectsUsersApi,
  projectTypesApi,
} from '../../paths';
import { ArgumentsProjectsCompaniesApi, ArgumentsProjectsUserApi } from '../../args';
import { IApiPath, ICompany, ITeam, IUser } from '@interfaces';

export const getProjectsCompaniesV2 = async (
  args: ArgumentsProjectsCompaniesApi,
): Promise<{ results: ICompany[] }> => dataProvider.get(projectsCompaniesApi(args)).json();

export const getProjectsUsersV2 = async (
  args: ArgumentsProjectsUserApi,
): Promise<{ results: IUser[] }> => dataProvider.get(projectsUsersApi(args)).json();

export const getProjectsTeamsV2 = async (
  args: ArgumentsProjectsUserApi,
): Promise<{ results: ITeam[] }> => dataProvider.get(projectsTeamsApi(args)).json();

export const getProjectsInspectionAgenciesV2 = async (
  args: IApiPath,
): Promise<{ results: ITeam[] }> => dataProvider.get(projectsInspectionAgenciesApi(args)).json();

export const getProjectTypesV2 = async (args: IApiPath): Promise<{ results: ITeam[] }> =>
  dataProvider.get(projectTypesApi(args)).json();
