import React, { useContext } from 'react';
import { Grid2, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

import {
  MessagePanelButton,
  ProjectHeader,
  ProjectSettingsOptionsMenu,
  ProjectWarning,
  WatchProject,
} from '@components';
import * as Controller from './controller';
import { LenderChecklist, ProjectScores, ServicesButton } from '..';
import { PermissionNamesEnums, ToolTipLineVariantEnum } from '@interfaces';
import { checkIsLender, checkIsOwner, isRestricted, WithPermission } from '@utils';
import { PermissionsContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import { WIDGET_TYPE } from '@constants';

const ProjectMainInfo = ({ handleRightDrawerOpenerClick }) => {
  const flags = useLaunchDarklyFlags();
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const { projectId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  const {
    showMessagePanel,
    teamRole,
    projectCommentsPreview,
    returnedRequestWarning,
    openWidget,
    closeWidget,
    widgetsOpened,
    hasYellowOrRedScore,
  } = Controller.useProjectMainInfo(projectId);

  return (
    <>
      {returnedRequestWarning && (
        <ProjectWarning
          innerElement={returnedRequestWarning}
          variant={ToolTipLineVariantEnum.NOTIFICATION}
        />
      )}
      <Grid2
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 3, pb: 1, px: 2 }}
        spacing={2}
      >
        <Grid2 sx={{ width: { md: 'auto', xs: '100%' } }}>
          <ProjectHeader />
        </Grid2>

        <Grid2 sx={{ width: { md: 'auto', xs: '100%' } }}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={1}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
          >
            {!isCurrentProjectArchived && (
              <WithPermission permissionKey={PermissionNamesEnums.SCORES_VIEW}>
                <ProjectScores
                  isWidgetOpen={!!widgetsOpened[WIDGET_TYPE.SCORES]}
                  onWidgetOpened={() => {
                    openWidget(WIDGET_TYPE.SCORES);
                    flags?.['ENG_9122_expand_scores_together_with_checklist'] &&
                      openWidget(WIDGET_TYPE.CHECKLIST);
                    closeWidget(WIDGET_TYPE.WATCHER);
                  }}
                  onClose={() => {
                    closeWidget(WIDGET_TYPE.SCORES);
                    flags?.['ENG_9122_expand_scores_together_with_checklist'] &&
                      closeWidget(WIDGET_TYPE.CHECKLIST);
                  }}
                  hasYellowOrRedScore={
                    flags?.['ENG_9122_expand_scores_together_with_checklist'] && hasYellowOrRedScore
                  }
                />
              </WithPermission>
            )}
            {!isMobileOnly && (
              <>
                <LenderChecklist
                  onWidgetOpened={() => {
                    openWidget(WIDGET_TYPE.CHECKLIST);
                    flags?.['ENG_9122_expand_scores_together_with_checklist'] &&
                      openWidget(WIDGET_TYPE.SCORES);
                    closeWidget(WIDGET_TYPE.WATCHER);
                  }}
                  isWidgetOpen={!!widgetsOpened[WIDGET_TYPE.CHECKLIST]}
                  onClose={() => {
                    closeWidget(WIDGET_TYPE.CHECKLIST);
                    flags?.['ENG_9122_expand_scores_together_with_checklist'] &&
                      closeWidget(WIDGET_TYPE.SCORES);
                  }}
                />
                {(checkIsLender(teamRole) ||
                  !isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)) && (
                  <WatchProject
                    onWidgetOpened={() => {
                      openWidget(WIDGET_TYPE.WATCHER);
                      closeWidget(WIDGET_TYPE.CHECKLIST);
                      closeWidget(WIDGET_TYPE.SCORES);
                    }}
                    isWidgetOpen={!!widgetsOpened[WIDGET_TYPE.WATCHER]}
                    onClose={() => closeWidget(WIDGET_TYPE.WATCHER)}
                  />
                )}
                <Stack direction="row" spacing={1} alignItems="center">
                  {showMessagePanel && (
                    <MessagePanelButton
                      commentsPreview={projectCommentsPreview}
                      handleRightDrawerOpenerClick={handleRightDrawerOpenerClick}
                      source="project"
                    />
                  )}
                  <ServicesButton withUserFocus={checkIsOwner(teamRole)} />
                  <ProjectSettingsOptionsMenu />
                </Stack>
              </>
            )}
          </Stack>
        </Grid2>
      </Grid2>
    </>
  );
};

export default ProjectMainInfo;
